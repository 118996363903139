<template>
  <div class="banner-container">
    <el-image
      :src="bannerInfo.swiperImg || swiperImg"
      alt=""
      style="width: 100%; height: 100%"
      fit="cover"
    />

    <div class="banner-text-container" :class="{'color-black': bannerInfo.blackcolor}">
      <div class="banner-text1">
        {{ bannerInfo.text1 }}
      </div>
      <div class="banner-text2">
        {{ bannerInfo.text2 }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerPart",
  components: {},
  props: {
    bannerInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {},
  data() {
    return {
      swiperList: [
        {
          img: require("@/assets/banner/banner1.png"),
        },
        // {
        //   img: require("@/assets/banner/banner1.png"),
        // },
      ],
      swiperImg: require("@/assets/banner/banner1.png")
    };
  },

  mounted() {},
  created() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.banner-container {
  width: 100%;
  height: 500px;
  position: relative;

  .banner-carousel {
    width: 100%;
    height: 100%;
    /deep/ .el-carousel__container {
      height: 100%;
    }
  }

  .banner-text-container {
    position: absolute;
    z-index: 99;
    width: 600px;
    left: 100px;
    height: 100px;
    top: 50%;
    color: #fff;
    transform: translateY(-80px);
    .banner-text1 {
      font-size: 58px;
      margin-bottom: 20px;
    }
    .banner-text2 {
      font-size: 20px;
    }
  }
  .color-black{
    color: #000;
  }
}
</style>
