<template>
  <div>
    <Nav></Nav>
    <banner-part :bannerInfo="bannerInfo"></banner-part>
    <div class="container">
      <!-- <tabs-section :defaultIndex="defaultIndex"></tabs-section> -->
    </div>
    <Footer class="top" />
  </div>
</template>

<script>
import BannerPart from "../components/BannerPart.vue";
import TabsSection from "../components/tabsSection.vue";
export default {
  components: { BannerPart, TabsSection },
  data() {
    return {
      bannerInfo: {
        text1: "产品服务",
        text2: "产品服务",
      },
      defaultIndex: "1",
    };
  },
  mounted() {
    this.defaultIndex = this.$route.params.index || "1";
    console.log("this.defaultIndex>>>", this.defaultIndex);
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 600px;
}
</style>